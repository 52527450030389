import { Route } from '@angular/router';
import { ServicesComponent } from '../../../../modules/front/modules/services/views/home/services.component';
import { ServiceCategoryComponent } from '../../../../modules/front/modules/services/views/category/service-category.component';
import { ServiceShowComponent } from '../../../../modules/front/modules/services/views/show/service-show.component';

export default {
  path: 'services',
  children: [
    {
      path: '',
      component: ServicesComponent,
    },
    {
      path: ':category',
      children: [
        {
          path: '',
          component: ServiceCategoryComponent,
        },
        {
          path: ':service',
          component: ServiceShowComponent,
        },
      ],
    },
  ],
} as Route;
