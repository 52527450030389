import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import {
  provideRouter,
  withComponentInputBinding,
  withDebugTracing,
} from '@angular/router';
import { routes } from './routes/app.routes';
import {
  provideClientHydration,
  withEventReplay,
} from '@angular/platform-browser';
import { environment } from '../environments/environment';
import {
  provideHttpClient,
  withFetch,
  withInterceptors,
} from '@angular/common/http';
import {
  DashboardProviders,
  GraphqlProvider,
  HttpClientInterceptors,
} from '@ckmk/angular';
import { DashboardViewService } from './modules/admin/services/view/dashboard.view.service';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(
      routes,
      withComponentInputBinding(),
      ...(environment.production ? [] : [withDebugTracing()]),
    ),
    provideClientHydration(withEventReplay()),
    provideHttpClient(
      withFetch(),
      withInterceptors([...HttpClientInterceptors]),
    ),
    provideHttpClient(),
    GraphqlProvider,
    ...DashboardProviders({
      viewService: DashboardViewService,
    }),
  ],
};
