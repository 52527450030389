import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { HtmlMetaService } from '@ckmk/angular';
import { getDevLastNews } from '../../../../../../utils/dev.utils';
import { FrontHeaderComponent } from '../../../../components/front-header/front-header.component';
import { FrontSectionComponent } from '../../../../components/front-section/front-section.component';
import { environment } from '../../../../../../../environments/environment';

@Component({
  selector: 'new-show',
  standalone: true,
  imports: [FrontHeaderComponent, FrontSectionComponent],
  templateUrl: './new-show.component.html',
  styleUrl: './new-show.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class NewShowComponent implements OnInit, OnChanges {
  @Input({
    required: true,
    alias: 'new',
  })
  public newSlug!: string;

  protected newItem: any;

  public constructor(
    private readonly _self: ElementRef,
    private readonly _htmlService: HtmlMetaService,
  ) {}

  public ngOnInit() {
    this._initNew();
  }

  public ngOnChanges({ newSlug }: SimpleChanges) {
    if (newSlug && !newSlug.firstChange) {
      this._initNew();
    }
  }

  private _getNew(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      resolve(getDevLastNews(12).find((it) => it.slug === this.newSlug));
    });
  }

  private _initNew(): void {
    this._getNew().then((res) => {
      this.newItem = res;

      this._htmlService.setViewTitle(this.newItem.title, environment.SITE_NAME);
    });
  }
}
