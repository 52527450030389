import { Route } from '@angular/router';
import { FrontComponent } from '../../modules/front/components/front/front.component';
import HomeRoutes from './routes/home/home.routes';
import AboutRoutes from './routes/about/about.routes';
import RseRoutes from './routes/rse/rse.routes';
import ContactRoutes from './routes/contact/contact.routes';
import NewsRoutes from './routes/news/news.routes';
import ServicesRoutes from './routes/services/services.routes';
import SolutionsRoutes from './routes/solutions/solutions.routes';
import NotFoundRoutes from './routes/not-found/not-found.routes';

export default {
  path: '',
  component: FrontComponent,
  children: [
    HomeRoutes,
    AboutRoutes,
    RseRoutes,
    ContactRoutes,
    NewsRoutes,
    ServicesRoutes,
    SolutionsRoutes,
    NotFoundRoutes,
  ],
} as Route;
