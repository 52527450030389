import { Route } from '@angular/router';
import { NewsComponent } from '../../../../modules/front/modules/news/views/home/news.component';
import { NewShowComponent } from '../../../../modules/front/modules/news/views/show/new-show.component';

export default {
  path: 'news',
  children: [
    {
      path: '',
      component: NewsComponent,
    },
    {
      path: ':new',
      component: NewShowComponent,
    },
  ],
} as Route;
